// import { navigate } from "gatsby"

import React, { useState } from "react"
import BreadcrumbForgotPassword from "./breadcrumb"
import DisplayInputEmail from "./displayInputEmail"
import DisplaySentEmail from "./displaySentEmail"
import "./index.css"

const ForgotPassword = () => {
  const [sentEmail, setSentEmail] = useState(false)

  return (
    <div>
      <div className="pt-3 pl-8">
        <BreadcrumbForgotPassword />
      </div>
      {!sentEmail && <DisplayInputEmail setSentEmail={setSentEmail} />}
      {sentEmail && <DisplaySentEmail />}
    </div>
  )
}

export default ForgotPassword
