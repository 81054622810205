import React from "react"
import SignInLayout from "../components/signin-layout"

import SEO from "../components/seo"
import ForgotPassword from "../domain/forgot-password"

const ForgotPasswordPage = () => {
  return (
    <SignInLayout>
      <SEO title="Forgot password" />
      <ForgotPassword />
    </SignInLayout>
  )
}

export default ForgotPasswordPage
