import React from "react"
import { Breadcrumb } from "antd"

const BreadcrumbForgotPassword = () => {
  return (
    <Breadcrumb separator=">">
      <Breadcrumb.Item>TOP</Breadcrumb.Item>
      <Breadcrumb.Item>パスワードを忘れた方</Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default BreadcrumbForgotPassword
